import {useMainFundraisingDataService} from "~/composables/useMainFundraisingDataService";
import type {FundraisingCampaign, FundraisingInstantSearchData} from "~/types";
import {useFundraising} from "#build/imports";

export const useMainFundraisingDataManager = () => {
    const fundraisingStore = useFundraising();
    const mainFundraisingDataService = useMainFundraisingDataService();

    const getMainFundraisingCampaign = async () => {
        return await mainFundraisingDataService.fetchMainFundraisingCampaign();
    }

    const getMainFundraisingCampaigns = async () => {
        const results = await mainFundraisingDataService.fetchMainFundraisingCampaignsData();
        return results?.value;
    }

    const getMainFundraisingCampaignsFromUrl = async (url: string) => {
        return await mainFundraisingDataService.fetchMainFundraisingCampaignsDataFromUrl(url);
    }
    
    const getFundraisingCampaignReportTypes = async () => {
        const storedFundraisingCampaignReportTypes = computed(() => {
            return fundraisingStore.fundraising_campaign_report_types;
        })
        if (storedFundraisingCampaignReportTypes.value == null || storedFundraisingCampaignReportTypes.value.length == 0 || !fundraisingStore.isFundraisingCampaignReportTypesValid()) {
            const fundraisingCampaignReportTypes =  await mainFundraisingDataService.fetchFundraisingCampaignReportTypes();
            if(fundraisingCampaignReportTypes)
                fundraisingStore.setFundraisingCampaignReportTypes(fundraisingCampaignReportTypes);
        }

        return storedFundraisingCampaignReportTypes;
    }

    const reportFundraisingCampaign = async (data:object) => {
        const createdFundraisingCampaignReport = await mainFundraisingDataService.requestToCreateNewFundraisingCampaignReport(data);
        if (createdFundraisingCampaignReport) {
            useAlertSetter().setSuccessAlert("Fundraiser Reported Successfully");
            return createdFundraisingCampaignReport;
        }
        return null;
    }

    const saveFundraisingCampaign = async () => {
        const savedFundraisingCampaign = await mainFundraisingDataService.requestToSaveAFundraisingCampaign();
        if (savedFundraisingCampaign) {
            useAlertSetter().setSuccessAlert("Fundraiser Saved Successfully");
            return savedFundraisingCampaign;
        }
        return null;
    }

    const donateToFundraisingCampaign = async (fundraisingCampaign:FundraisingCampaign, donationForm:object) => {
        const donation = await mainFundraisingDataService.requestToDonateToAFundraisingCampaign(donationForm);
        if (donation) {
            useFundraising().setCurrentDonation(donation);
            navigateTo({
                name: "fundraising-slug-donate-thank-you",
                params: {
                    slug: fundraisingCampaign.slug,
                },
            });
            useAlertSetter().setSuccessAlert("Successfully Donated!");
            return donation;
        }
        return null;
    }

    const getFundraisingCampaignInstantSearchResults = async (query:string) => {
        return await mainFundraisingDataService.fetchInstantSearchResults(query) as FundraisingInstantSearchData;
    }

    const searchFundraisingCampaignsByCriteria = async (query:string)=>{
        const searchResults =  await mainFundraisingDataService.fetchFundraisingCampaignsBySearchCriteria(query);
        if(searchResults){
            console.log(searchResults);
            return searchResults
        } else{
            return null;
        }
    }

    return {
        getMainFundraisingCampaign,
        getFundraisingCampaignReportTypes,
        reportFundraisingCampaign,
        saveFundraisingCampaign,
        donateToFundraisingCampaign,
        getMainFundraisingCampaigns,
        getMainFundraisingCampaignsFromUrl,


        getFundraisingCampaignInstantSearchResults,
        searchFundraisingCampaignsByCriteria
    }
}